import React, { useState } from "react";

import "./packing.css";
import Header from "./header/Header";
import Footer from "./footer/Footer";
// import PrintList from "./PrintList";

const Packing = () => {
  const [itemName, setItemName] = useState("");
  const [itemQuantity, setItemQuantity] = useState(1);
  const [items, setItems] = useState([]);

  const itemsCarry = items.length;
  const numPacked = items.filter((item) => item.packed).length;
  const percentage = Math.round((numPacked / itemsCarry) * 100);

  console.log(items);

  console.log("items length is: " + itemsCarry);

  const addItem = () => {
    if (itemName.trim() === "") return;
    const newItem = { name: itemName, quantity: itemQuantity, packed: false };
    console.log(newItem);
    setItems([...items, newItem]);

    // form rest krn lyi
    setItemName("");
    setItemQuantity(1);
  };

  const removeItem = (index) => {
    const newItems = items.filter((_, i) => i !== index);

    setItems(newItems);
  };

  function onToggleItem(id) {
    setItems((items) =>
      items.map((item, index) =>
        index === id ? { ...item, packed: !item.packed } : item
      )
    );
  }

  return (
    <>
      <Header></Header>
      <div className="pt-5 container">
        <div className="card text-center">
          <div className="card-header h2">Travel Packing List</div>
          <div className="card-body">
            <div className="container">
              <div className="row">
                <div className="col-5">
                  <input
                    className="form-control"
                    type="text"
                    name="itemName"
                    id="itemName"
                    placeholder="Add Item Name..."
                    value={itemName}
                    onChange={(e) => setItemName(e.target.value)}
                  />
                </div>
                <div className="col-5">
                  <select
                    className="form-select"
                    name="itemQuantity"
                    id="itemQuantity"
                    value={itemQuantity}
                    onChange={(e) => setItemQuantity(Number(e.target.value))}
                  >
                    {Array.from({ length: 20 }, (_, idx) => `${++idx}`).map(
                      (num, index) => {
                        return (
                          <option key={index} value={num}>
                            {num}
                          </option>
                        );
                      }
                    )}
                  </select>
                </div>
                <div className="col-2">
                  <button className="btn btn-primary" onClick={addItem}>
                    Add
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className="list-group p-4">
            <ul className="list-group d-flex flex-row flex-wrap">
              {items.map((item, index) => (
                <li
                  key={index}
                  className={`list-group-item d-flex justify-content-between align-items-center ${
                    item.packed ? "bg bg-danger text-light" : ""
                  }`}
                >
                  <div>
                    <input
                      type="checkbox"
                      className="form-check-input"
                      value={item.packed}
                      onChange={() => onToggleItem(index)}
                    />

                    <span className="ms-3">{item.quantity}</span>
                    <span
                      className={`p-2 ${
                        item.packed ? "text-decoration-line-through" : ""
                      }`}
                    >
                      {item.name}
                    </span>
                  </div>
                  <button
                    className={`p-2 ${
                      item.packed
                        ? "badge bg-light text-danger"
                        : "badge bg-danger text-light"
                    }`}
                    onClick={() => removeItem(index)}
                  >
                    X
                  </button>
                </li>
              ))}
            </ul>

            {!items.length ? (
              <>
                <p className="list-group-item list-group-item-info mt-2">
                  <em>Start adding some items to your packing list 🚀</em>
                </p>
              </>
            ) : (
              <>
                <p className="list-group-item list-group-item-info mt-4">
                  {percentage === 100
                    ? "You got everything! Ready to go ✈️"
                    : ` 💼 You have ${itemsCarry} items on your list, and you already packed ${numPacked} (${percentage}%)`}
                </p>
              </>
            )}

            {/* <PrintList itemsArr={items}></PrintList> */}
          </div>
        </div>
      </div>
      <Footer></Footer>
    </>
  );
};

export default Packing;
