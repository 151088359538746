import React from "react";

const Header = () => {
  return (
    <>
      <nav className="navbar navbar-dark bg-dark">
        <div className="container-fluid">
          <a className="navbar-brand" href="#">
            <img
              src="logo192.png"
              alt=""
              width="30"
              height="24"
              className="d-inline-block align-text-top"
            />
            Travel Packing App
          </a>
        </div>
      </nav>
    </>
  );
};

export default Header;
