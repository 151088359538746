import React from "react";
const Footer = () => {
  return (
    <>
      <footer className="pt-5 my-5 text-muted border-top text-center">
        Created by Gurjeet Singh© 2024
      </footer>
    </>
  );
};

export default Footer;
